import React from 'react';

import classNames from 'classnames';

import { CertificationRGE, Deal, DevisCustomization } from '@travauxlib/shared/src/types';
import { Company } from '@travauxlib/shared/src/types/company';
import { headerElementsPosition } from '@travauxlib/shared/src/utils/constants';

import { Certifications } from 'components/DocumentHeader/Certifications';
import { CompanyInfos } from 'components/DocumentHeader/CompanyInfos';
import { DealDestinataire } from 'components/DocumentHeader/DealDestinataire';
import { Logo } from 'components/DocumentHeader/Logo';
import { Title } from 'components/DocumentHeader/Title';

type Props = {
  company: Company;
  title?: string;
  deal: Deal;
  dateBlock: JSX.Element;
  customization?: DevisCustomization;
  proCompanyCertificationsRGE: CertificationRGE[];
};

export const DocumentHeaderWithPro: React.FC<Props> = ({
  deal,
  company,
  title,
  dateBlock,
  customization,
  proCompanyCertificationsRGE,
}) => {
  const { logoUrl, selfDeclaredCertifications = [], customCertifications = [] } = company;
  const { isHemea, dealOwnerFullname } = deal;

  return (
    <div className="mb-md">
      <div
        className={classNames(
          'flex justify-between items-start mb-md flex-wrap-reverse sm-desktop:flex-nowrap',
          customization?.headerElementsPosition &&
            headerElementsPosition[customization.headerElementsPosition].logoAndDates,
        )}
      >
        <div className="mr-xl">
          {logoUrl && (
            <Logo
              data-testid="logo"
              className="mb-sm"
              src={logoUrl}
              alt=""
              customSize={customization?.logoSize}
            />
          )}
        </div>
        <div className="mb-md sm-desktop:mb-0 text-right">
          <Title isHemea={isHemea} title={title} />
          {dealOwnerFullname && (
            <div className="py-xs text-ds-sm bold text-right">
              Projet suivi par {dealOwnerFullname}
            </div>
          )}
          {dateBlock}
        </div>
      </div>
      <div
        className={classNames(
          'flex justify-between mb-md flex-wrap sm-desktop:flex-nowrap',
          customization?.headerElementsPosition &&
            headerElementsPosition[customization?.headerElementsPosition].addresses,
        )}
      >
        <div className="mb-xl sm-desktop:mb-0 mr-xl sm-desktop:mr-[8rem]">
          <CompanyInfos company={company} isHemea={isHemea} />
        </div>
        <div
          className={classNames('h-full', {
            'w-1/2': customization?.headerElementsPosition === 'enveloppe',
          })}
        >
          <DealDestinataire deal={deal} />
        </div>
      </div>
      <Certifications
        selfDeclaredCertifications={selfDeclaredCertifications}
        customCertifications={customCertifications}
        proCompanyCertificationsRGE={proCompanyCertificationsRGE}
      />
    </div>
  );
};
