import React from 'react';

import { useNavigate } from 'react-router-dom';

import Quote from '@travauxlib/shared/src/components/DesignSystem/assets/Quote.svg?react';
import QuoteAdd from '@travauxlib/shared/src/components/DesignSystem/assets/QuoteAdd.svg?react';
import { Props as TagProps } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { DevisStatuses } from '@travauxlib/shared/src/types';

import { DevisMinimalView } from 'types';

import { DevisCard } from './DevisCard';

type Props = {
  title: string;
  devis: DevisMinimalView;
  isArchitecte: boolean;
};

const tagByStatus: { [K in DevisStatuses]?: { label: string; variant: TagProps['variant'] } } = {
  [DevisStatuses.sent]: { label: 'Reçu', variant: 'warning' },
  [DevisStatuses.signed]: { label: 'Signé', variant: 'success' },
};

export const MainDevisCard: React.FC<Props> = ({ title, devis, isArchitecte }) => {
  const navigate = useNavigate();

  const isTSFromNonArchitecte = !isArchitecte && devis.isTS;

  return (
    <DevisCard
      icon={isTSFromNonArchitecte ? <QuoteAdd /> : <Quote />}
      title={title}
      version={devis.version || 1}
      tag={tagByStatus[devis.status] ?? tagByStatus[DevisStatuses.sent]!}
      prixTotalTTC={devis.prixTotalTTC}
      onChange={() => navigate(`projets-pro/${devis.projectUuid}`)}
      size={devis.status === DevisStatuses.signed ? 'sm' : 'md'}
    />
  );
};
