import _sum from 'lodash/sumBy';

import ChatOutline from '@travauxlib/shared/src/components/DesignSystem/assets/ChatOutline.svg?react';
import DocumentOutline from '@travauxlib/shared/src/components/DesignSystem/assets/DocumentOutline.svg?react';
import Euro from '@travauxlib/shared/src/components/DesignSystem/assets/Euro.svg?react';
import HandyManOutline from '@travauxlib/shared/src/components/DesignSystem/assets/HandyManOutline.svg?react';
import Home from '@travauxlib/shared/src/components/DesignSystem/assets/Home.svg?react';
import { DevisStatuses } from '@travauxlib/shared/src/types';

import { Badges, Chantier } from 'features/EspaceClient3/types';

import { NavigationItem } from '../types';

export const getNavigationItems = (
  projectUuid: string,
  pathname: string,
  badges: Badges,
): NavigationItem[] => [
  {
    Icon: Home,
    label: 'Accueil',
    path: `/projets/${projectUuid}`,
    isActive: pathname === `/projets/${projectUuid}`,
    badgeCount: badges.actions,
  },
  {
    Icon: Euro,
    label: 'Paiements',
    path: 'paiements',
    isActive: pathname.startsWith(`/projets/${projectUuid}/paiements`),
    badgeCount: _sum(Object.values(badges.payments)),
  },
  {
    Icon: HandyManOutline,
    label: 'Chantier',
    path: 'chantiers',
    isActive: pathname.startsWith(`/projets/${projectUuid}/chantiers`),
    badgeCount: 0,
  },
  {
    Icon: DocumentOutline,
    label: 'Documents',
    path: 'documents',
    isActive: pathname.startsWith(`/projets/${projectUuid}/documents`),
    badgeCount: _sum(Object.values(badges.companies)),
  },
  {
    Icon: ChatOutline,
    label: 'Guides',
    path: 'guides',
    isActive: pathname.startsWith(`/projets/${projectUuid}/guides`),
    badgeCount: 0,
  },
];

export const routesWithoutHeaderTapBar = ['suivi-chantier-recap/', 'suivi-chantier/', 'factures/'];
export const routesWithoutLayout = ['suivi-chantier/'];

export const formatIBANForDisplay = (chantier: Chantier): string =>
  [...chantier.iban.replaceAll(' ', '')].reduce(
    (acc, c, idx) => `${acc}${idx % 4 === 0 ? ' ' : ''}${c}`,
    '',
  );

export const routesWithoutTapBar = ['factures', 'devis', 'guides/'];

export const StatusDisplay: { [key in DevisStatuses]: string } = {
  [DevisStatuses.signed]: 'Signé',
  [DevisStatuses.sent]: 'Reçu',
  [DevisStatuses.rejected]: 'Refusé',
  [DevisStatuses.draft]: 'Brouillon',
  [DevisStatuses.canceled]: 'Annulé',
  [DevisStatuses.to_be_revised]: 'À réviser',
};
