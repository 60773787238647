import React from 'react';

import { ProContext } from '@travauxlib/shared/src/types';

import { DocumentHeaderWithAdminContext } from './DocumentHeaderWithAdminContext';
import { DocumentHeaderWithPro } from './DocumentHeaderWithPro';

import { AdminContext } from '../../types';

type Props = {
  proContext?: ProContext;
  adminContext?: AdminContext;
  title?: string;
  dateBlock: JSX.Element;
};

export const DocumentHeader: React.FC<Props> = ({ proContext, title, dateBlock, adminContext }) => (
  <>
    {proContext && (
      <DocumentHeaderWithPro
        company={proContext.company}
        deal={proContext.deal}
        customization={proContext.deal.isHemea ? undefined : proContext.customization}
        proCompanyCertificationsRGE={proContext.proCompanyCertificationsRGE}
        title={title}
        dateBlock={dateBlock}
      />
    )}
    {adminContext && <DocumentHeaderWithAdminContext title={title} adminContext={adminContext} />}
  </>
);
