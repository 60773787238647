import React from 'react';

import { useForm } from 'react-final-form';

import { SimpleModal } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { SuiviLot } from '@travauxlib/shared/src/features/SuiviChantier/types';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  lots: SuiviLot[];
  proCompanyName: string;
  globalPourcentageAvancement: number;
  montantALibererTTC: number;
  initialMontantALibererTTC: number;
};

export const ConfirmationSuiviChantierModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  lots,
  proCompanyName,
  globalPourcentageAvancement,
  montantALibererTTC,
  initialMontantALibererTTC,
}) => {
  const form = useForm();
  const { submitting } = form.getState();

  const changedLigne = lots.flatMap(lot => lot.lignes).filter(ligne => ligne.hasChanged);
  const changedLigneCount = changedLigne.length;
  const rejectedLigneCount = changedLigne.filter(
    ligne =>
      ligne.pourcentageAvancement !== ligne.previousStepPourcentageAvancement ||
      ligne.hasFournitures !== ligne.previousStepHasFournitures,
  ).length;

  const hasRejectedSomeLignes = rejectedLigneCount > 0;
  const hasRejectedAllLignes = rejectedLigneCount === changedLigneCount;

  const isMontantALibererNegative = hasRejectedSomeLignes && montantALibererTTC <= 0;

  return (
    <SimpleModal
      title={
        isMontantALibererNegative
          ? 'Voulez-vous confirmer ces modifications ?'
          : 'Voulez-vous confirmer la libération des fonds ?'
      }
      isOpen={isOpen}
      handleClose={handleClose}
      validateAction={{
        label: isMontantALibererNegative
          ? "Confirmer l'état d'avancement"
          : 'Confirmer la libération',
        onClick: () => form.submit(),
        disabled: submitting,
        loading: submitting,
      }}
      cancelAction={{ label: 'Annuler', onClick: handleClose }}
    >
      <div className="text-ds-b2 text-neutral-600">
        {hasRejectedSomeLignes ? (
          montantALibererTTC < 0 ? (
            <>
              Vous avez ajusté l’avancement sur <b>{rejectedLigneCount}</b> prestations. Le montant
              d’avancement total est maintenant inférieur au montant déjà libéré. Aucune libération
              de fonds ne sera effectuée et <b>{proCompanyName}</b> sera informé de vos ajustements.
            </>
          ) : montantALibererTTC === 0 && hasRejectedAllLignes ? (
            <>
              Vous avez refusé l’intégralité des avancements renseignés par <b>{proCompanyName}</b>.
              <br /> Aucune libération de fonds ne sera effectuée et <b>{proCompanyName}</b> en sera
              informé.
            </>
          ) : (
            <>
              Vous avez ajusté l’avancement sur <b>{rejectedLigneCount}</b> prestations.
              Confirmez-vous la libération de{' '}
              <EURCurrency className="font-bold" amount={montantALibererTTC} /> au lieu de{' '}
              <EURCurrency amount={initialMontantALibererTTC} /> initialement demandés par{' '}
              <b>{proCompanyName}</b> ?
            </>
          )
        ) : (
          <>
            Vous avez validé tous les avancements renseignés par <b>{proCompanyName}</b> sur votre
            chantier.
            <br />
            Confirmez-vous la libération des{' '}
            <EURCurrency className="font-bold" amount={montantALibererTTC} /> correspondant à un
            avancement global de <b>{globalPourcentageAvancement}%</b> ?
          </>
        )}
      </div>
    </SimpleModal>
  );
};
