import React from 'react';

import { Field, useField } from 'react-final-form';

import { LigneStatus } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi/components/MainSuivi/LigneStatus';

import { SuiviLigne } from '../../types';

type Props = {
  ligne: SuiviLigne;
  namePrefix: string;
  isEditing?: string;
  setIsEditing: (isEditing: string) => void;
};

export const ValidateAvancementClient: React.FC<Props> = ({
  ligne,
  namePrefix,
  isEditing,
  setIsEditing,
}) => {
  const {
    input: { value: hasFournitures, onChange: onChangeFournitures },
  } = useField<boolean>(`${namePrefix}.hasFournitures`);

  return (
    <Field
      name={`${namePrefix}.pourcentageAvancement`}
      id={`avancement-${ligne.uuid}`}
      render={({ input: { onChange, value } }) => (
        <LigneStatus
          name={namePrefix}
          onChangeAvancement={onChange}
          onChangeFournitures={onChangeFournitures}
          ligne={ligne}
          pourcentageAvancement={value}
          hasFournitures={hasFournitures}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
    />
  );
};
