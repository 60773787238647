import React, { useEffect } from 'react';

import classNames from 'classnames';
import _orderBy from 'lodash/orderBy';

import Gift from '@travauxlib/shared/src/components/DesignSystem/assets/Gift.svg?react';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { MultilineParagraph } from '@travauxlib/shared/src/components/MultilineParagraph';
import { DevisDisplay } from '@travauxlib/shared/src/features/DevisDisplay';
import { ColoredElement } from '@travauxlib/shared/src/features/DevisDisplay/components/Header';
import { getDetailedDevisFacturationStatus } from '@travauxlib/shared/src/utils/facture/getDetailedDevisFacturationStatus';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { FacturePrestationsSummary } from 'components/DevisFactures/FacturePrestationsSummary';
import { FactureSoldePrestationsSummary } from 'components/DevisFactures/FactureSoldePrestationsSummary';
import { DocumentHeaderWithPro } from 'components/DocumentHeader/DocumentHeaderWithPro';
import { RootPdf } from 'components/RootPdf';
import { useDevisPro } from 'features/DevisPro/api/useDevisPro';
import { LinkedFactureDisplay } from 'features/Factures/components/LinkedFactureDisplay';
import { useIsAuthor } from 'hooks/useIsAuthor';
import { Facture } from 'types';

import { PaidFacture } from './FacturePayee';

type Props = {
  facture: Facture;
};

export const FacturePrint: React.FC<Props> = ({ facture }) => {
  const {
    numero,
    company,
    deal,
    title,
    lots,
    headerText,
    introductionLetter,
    montantRestantAPayer,
    dateEmission,
    customization,
    linkedFactures,
    showFournitures,
    proDevisToken,
  } = facture;

  useEffect(() => {
    window.status = 'ready';
  }, []);

  const { devis } = useDevisPro(proDevisToken);
  const sortedLinkedFactures = _orderBy(linkedFactures, 'dateEmission', 'desc');
  const facturationStatus =
    devis && getDetailedDevisFacturationStatus([...sortedLinkedFactures, facture], devis);

  const isAuthor = useIsAuthor();
  const chantierAddress = deal.chantierAddress || deal.billingAddress;

  const isFacturePaid = montantRestantAPayer === 0;
  const customizationData = !deal.isHemea ? customization : undefined;
  return (
    <RootPdf>
      {isFacturePaid && (
        <PaidFacture className="z-10" data-testid="paid-facture">
          Facture Payée
        </PaidFacture>
      )}
      <DocumentHeaderWithPro
        company={company}
        deal={deal}
        customization={customizationData}
        title={numero && `Facture n°${numero}`}
        dateBlock={<span>En date du {formatFrenchDate(dateEmission)}</span>}
        proCompanyCertificationsRGE={facture.proCompanyCertificationsRGE}
      />
      <h1 className={classNames(customizationData?.titleFontStyle)}>{title}</h1>
      <div className="!mb-lg">
        {chantierAddress.address} {chantierAddress.postalCode} {chantierAddress.city}
      </div>
      <div className="!mb-[5.5rem]">
        <MultilineParagraph data-testid="multi-paragraph" text={introductionLetter} />
      </div>
      <div className="!mb-lg">
        <DevisDisplay
          isAuthor={isAuthor}
          lots={lots}
          headerText={headerText}
          locations={[]}
          showOnlyFacturable
          customization={customizationData}
          customerName={deal.customerName}
          proCompanyName={company.name}
          showFournitures={showFournitures}
          showTotalTravaux={facture.isCloture && !facture.isSituation}
          token={facture.token}
        />
        {facture.isCloture &&
          !facture.isSituation &&
          (facturationStatus?.factureSoldeTotals?.montantRemise || 0) > 0 && (
            <>
              <div className="flex flex-row items-center justify-between text-ds-b2 mt-xs text-primary">
                <div className="flex items-center">
                  <div className="w-md h-md flex items-center mr-xxs">
                    <Gift />
                  </div>
                  Remise
                </div>
                <EURCurrency
                  amount={facturationStatus?.factureSoldeTotals?.montantRemise}
                  suffix="HT"
                />
              </div>
              <div className="flex flex-row items-center justify-between font-bold mt-xs">
                <div>Total Facturable</div>
                <EURCurrency
                  amount={
                    (facturationStatus?.factureSoldeTotals?.totalTravauxHT || 0) -
                    (facturationStatus?.factureSoldeTotals?.montantRemise || 0)
                  }
                  suffix="HT"
                />
              </div>
            </>
          )}
      </div>
      {facture.isCloture && (
        <div className="mb-xxl">
          <ColoredElement
            className={classNames('mb-md text-ds-h4 font-medium', customization?.titleFontStyle)}
            color={customizationData?.textColor}
          >
            Acomptes déjà facturés
          </ColoredElement>

          <LinkedFactureDisplay facturesList={sortedLinkedFactures} />
        </div>
      )}
      <div className="break-inside-avoid !pt-xl">
        {facture.isCloture && !facture.isSituation ? (
          facturationStatus?.factureSoldeTotals && (
            <FactureSoldePrestationsSummary
              facture={facture}
              factureSoldeTotals={facturationStatus?.factureSoldeTotals}
            />
          )
        ) : (
          <FacturePrestationsSummary facture={facture} customization={customizationData} />
        )}
      </div>
    </RootPdf>
  );
};
