import React from 'react';

import ChevronRight from '@travauxlib/shared/src/components/DesignSystem/assets/ChevronRight.svg?react';
import { ListItem } from '@travauxlib/shared/src/components/DesignSystem/components/ListItem';
import { Props as TagProps } from '@travauxlib/shared/src/components/DesignSystem/components/Tag';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';

type Props = {
  icon: React.ReactNode;
  title: string;
  version: number;
  tag: { label: string; variant: TagProps['variant'] };
  prixTotalTTC: number;
  onChange: () => void;
  size?: 'md' | 'sm';
};

export const DevisCard: React.FC<Props> = ({
  icon,
  title,
  version,
  tag,
  prixTotalTTC,
  onChange,
  size,
}) => (
  <ListItem
    className="rounded-sm"
    onChange={onChange}
    left={{ type: 'iconBackground', icon, size: 'lg', variant: 'beige' }}
    middle={{
      // This throws when something is undefined !
      title: <div className="line-clamp-2">{title}</div>,
      tags: [tag],
    }}
    right={{ type: 'icon', icon: <ChevronRight /> }}
    shouldTruncate={false}
    size={size}
  >
    <div className="flex justify-between gap-xxs text-ds-b2 -mt-xxs">
      <div className="text-neutral-600">Version {version}</div>
      <EURCurrency className="font-medium" amount={prixTotalTTC} />
    </div>
  </ListItem>
);
