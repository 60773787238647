import React, { ReactNode } from 'react';

import classNames from 'classnames';

import { PaidFacture as NormalPaidFacture } from 'features/Factures/components/FacturePayee';

export const PaidFacture: React.FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <NormalPaidFacture
    className={classNames(
      '!absolute !w-[400px] ml-1/2 !top-[350px] text-center translate-y-[-200px] rotate-[-25deg]',
      className,
    )}
  >
    {children}
  </NormalPaidFacture>
);
