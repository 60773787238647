import { useMemo } from 'react';

import dayjs from 'dayjs';
import _flow from 'lodash/flow';
import _groupBy from 'lodash/groupBy';

import { LigneStatus, SuiviLigneStatus } from '@travauxlib/shared/src/types';
import { predefinedLots } from '@travauxlib/shared/src/utils/devisConfig';

import { FormData, SuiviDevis, SuiviLigne, SuiviLot } from '../types';

const applyWithIsModifyingAnotherLigne = (ligne: SuiviLigne, allLots: SuiviLot[]): SuiviLigne => {
  const isModifyingAnotherLigne = !!allLots
    .flatMap(lot => lot.lignes)
    .find(
      l =>
        l.status === LigneStatus.Modified &&
        l.linkedToDevisItemUuid === ligne.linkedToDevisItemUuid,
    );

  return { ...ligne, isModifyingAnotherLigne };
};

const applyWithSuiviStatus = (ligne: SuiviLigne): SuiviLigne => {
  const isLigneRejected =
    ligne.proPreviousStepPourcentageAvancement !== undefined &&
    (ligne.proPreviousStepPourcentageAvancement !== ligne.previousStepPourcentageAvancement ||
      ligne.proPreviousStepHasFournitures !== ligne.previousStepHasFournitures);

  return {
    ...ligne,
    previousStepHasFournitures:
      ligne.proPreviousStepHasFournitures ?? ligne.previousStepHasFournitures,
    previousStepPourcentageAvancement:
      ligne.proPreviousStepPourcentageAvancement ?? ligne.previousStepPourcentageAvancement,
    suiviStatus:
      ligne.proPreviousStepPourcentageAvancement !== undefined
        ? isLigneRejected
          ? SuiviLigneStatus.Rejected
          : SuiviLigneStatus.Agreed
        : undefined,
    commentaire: isLigneRejected ? ligne.commentaire : undefined,
  };
};

const applySuiviChantierDraft =
  (suiviChantierDraftByLigneUuid: Record<string, SuiviLigne[]>) =>
  (ligne: SuiviLigne): SuiviLigne => {
    const maybeCachedLigne = suiviChantierDraftByLigneUuid[ligne.uuid]?.[0];

    return {
      ...ligne,
      pourcentageAvancement:
        maybeCachedLigne?.pourcentageAvancement ?? ligne.previousStepPourcentageAvancement,
      hasFournitures: maybeCachedLigne?.hasFournitures ?? ligne.previousStepHasFournitures,
      suiviStatus: maybeCachedLigne?.suiviStatus ?? ligne.suiviStatus,
      commentaire: maybeCachedLigne?.commentaire ?? ligne.commentaire,
    };
  };

type Payload = {
  devisList?: SuiviDevis[];
  withIsModifyingAnotherLigne?: boolean;
  withSuiviStatus?: boolean;
  withPreviousCycle?: boolean;
  suiviChantierDraft?: FormData;
};

export const useSuiviInitialValues = ({
  devisList,
  withIsModifyingAnotherLigne,
  withSuiviStatus,
  withPreviousCycle,
  suiviChantierDraft,
}: Payload): FormData => {
  // TODO: This useMemo is used as gating the following renders wich is not good. Find a better solution but spare
  // TODO: Jalil from redoing all the tests again and again because we changed that for now :)
  const orderedDevisList = useMemo(
    () =>
      devisList
        ?.slice()
        ?.sort((devisA, devisB) => dayjs(devisA.signedAt).diff(dayjs(devisB.signedAt))),
    [JSON.stringify(devisList)],
  );

  return useMemo<FormData>(() => {
    const lignesWithStatus = orderedDevisList?.flatMap(devis =>
      devis.lots.flatMap(lot => lot.lignes.filter(ligne => ligne.status)),
    );
    const ligneUuidsThatHaveBeenReworkedOnTS = lignesWithStatus?.map(
      ligne => ligne.linkedToDevisItemUuid,
    );
    const canceledOrModifiedLignes = orderedDevisList?.flatMap(devis =>
      devis.lots.flatMap(lot =>
        lot.lignes
          .filter(
            ligne => ligne.status === LigneStatus.Canceled || ligne.status === LigneStatus.Modified,
          )
          .map(ligne => ligne.uuid),
      ),
    );

    const allLots = orderedDevisList?.flatMap((devis, index) =>
      devis.lots.map(lot => ({
        ...lot,
        devisToken: devis.token,
        devisNumber: index + 1,
      })),
    );

    const suiviChantierDraftByLigneUuid = Object.values(suiviChantierDraft ?? {})
      ?.map(({ lignes }) => _groupBy(lignes, 'uuid'))
      .reduce(
        (acc, v) => ({
          ...acc,
          ...v,
        }),
        {},
      );

    const modifiers: (suiviLigne: SuiviLigne, suiviLots: SuiviLot[]) => SuiviLigne = _flow([
      ...(withIsModifyingAnotherLigne ? [applyWithIsModifyingAnotherLigne] : []),
      ...(withSuiviStatus ? [applyWithSuiviStatus] : []),
      ...(suiviChantierDraft ? [applySuiviChantierDraft(suiviChantierDraftByLigneUuid)] : []),
    ]);

    return allLots
      ? allLots
          .map(lot => ({
            ...lot,
            lignes: lot.lignes
              .flatMap(l =>
                ligneUuidsThatHaveBeenReworkedOnTS?.includes(l.uuid) ||
                canceledOrModifiedLignes?.includes(l.uuid)
                  ? []
                  : [
                      modifiers(
                        {
                          ...l,
                          pourcentageAvancement: withPreviousCycle
                            ? l.previousCyclePourcentageAvancement
                            : l.previousStepPourcentageAvancement,
                          hasFournitures: withPreviousCycle
                            ? l.previousCycleHasFournitures
                            : l.previousStepHasFournitures,
                          previousStepPourcentageAvancement: withPreviousCycle
                            ? l.previousCyclePourcentageAvancement
                            : l.previousStepPourcentageAvancement,
                        },
                        allLots,
                      ),
                    ],
              )
              .sort((a, b) => (a.order ?? -1) - (b.order ?? -1))
              // After removing and ordering lignes in lot, we need to save their index in lignes array to use it in final form
              .map((ligne, index) => ({ ...ligne, indexInLot: index })),
          }))
          // Remove lot without lignes
          .filter(lot => lot.lignes.length > 0)
          // Sort in standard lot order
          .sort(
            (lotA, lotB) => predefinedLots.indexOf(lotA.label) - predefinedLots.indexOf(lotB.label),
          )
          // Transform to have a format handled by final form. Like that a field could be queried with lotUuid.lignes[indexOfLigne]
          .reduce((acc, lot) => ({ ...acc, [lot.uuid]: lot }), {})
      : {};
  }, [orderedDevisList]);
};
