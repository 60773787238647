import React from 'react';

import { Field } from 'react-final-form';

import { IconButtonGroup } from '@travauxlib/shared/src/components/DesignSystem/components/IconButtonGroup';
import { SuiviLigne } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { required } from '@travauxlib/shared/src/utils/form';

import { LigneAvancementConflictModal } from './LigneAvancementConflictModal';

type Props = {
  name: string;
  onChangeAvancement: (newAvancement: number) => void;
  onChangeFournitures: (newSuiviLigneStatus: boolean) => void;
  ligne: SuiviLigne;
  pourcentageAvancement: number;
  hasFournitures: boolean;
  isEditing?: string;
  setIsEditing: (isEditing?: string) => void;
};

export const LigneStatus: React.FC<Props> = ({
  name,
  onChangeAvancement,
  onChangeFournitures,
  ligne,
  pourcentageAvancement,
  hasFournitures,
  isEditing,
  setIsEditing,
}) => (
  <Field<string>
    name={`${name}.suiviStatus`}
    id={`${name}.suiviStatus`}
    validate={required}
    render={({ input: { onChange: onChangeSuiviLigneStatus, value } }) => (
      <>
        <IconButtonGroup
          onChange={(iconButtonGroupValue: boolean) => {
            if (iconButtonGroupValue) {
              onChangeSuiviLigneStatus('agreed');
              onChangeAvancement(ligne.previousStepPourcentageAvancement);
              onChangeFournitures(ligne.previousStepHasFournitures);
            } else {
              setIsEditing(ligne.uuid);
            }
          }}
          value={value ? value === 'agreed' : undefined}
        />
        <Field
          name={`${name}.commentaire`}
          render={({ input: { onChange: onChangeComment, value } }) => (
            <LigneAvancementConflictModal
              isOpen={isEditing === ligne.uuid}
              onClose={() => setIsEditing(undefined)}
              onChange={(newAvancement, newComment, newHasFournitures) => {
                const newSuiviLigneStatus =
                  newAvancement === ligne.previousStepPourcentageAvancement &&
                  newHasFournitures === ligne.previousStepHasFournitures
                    ? 'agreed'
                    : 'rejected';

                onChangeAvancement(newAvancement);
                onChangeSuiviLigneStatus(newSuiviLigneStatus);
                onChangeComment(newSuiviLigneStatus === 'agreed' ? undefined : newComment);
                onChangeFournitures(newHasFournitures);
              }}
              initialPourcentageAvancement={pourcentageAvancement}
              initialCommentaire={value}
              initialHasFournitures={hasFournitures}
              ligne={ligne}
            />
          )}
        />
      </>
    )}
  />
);
