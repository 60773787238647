import React, { useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Navigate } from 'react-router-dom';

import { ReassuranceBlock } from '@travauxlib/shared/src/features/DevisDisplay/components/ReassuranceBlock';
import { FinancialReassuranceBlock } from '@travauxlib/shared/src/features/DevisDisplay/components/ReassuranceBlock/FinancialReassuranceBlock';

import { factureReassuranceItems } from 'features/Factures/config';
import { Facture } from 'types';

import { FacturePaymentCardForm } from './FacturePaymentCardForm';
import { FacturePaymentSuccess } from './FacturePaymentSuccess';

type DevisPaymentProps = {
  facture: Facture;
};

export const FacturePayment: React.FC<DevisPaymentProps> = ({ facture }) => {
  const stripePromise = loadStripe(APP_CONFIG.stripe.publicKey, {
    stripeAccount: facture.company?.stripeConnectAccountId,
  });
  const [completed, setCompleted] = useState(false);

  if (facture.montantRestantAPayer === 0) {
    return <Navigate to={`/factures/${facture.token}`} />;
  }

  return (
    <div className="bg-neutral-100 py-md flex items-center justify-center min-h-full w-full">
      {completed ? (
        <FacturePaymentSuccess facture={facture} />
      ) : (
        <div className="max-w-[440px]">
          <div className="shadow-ds-sm bg-neutral-0 rounded flex flex-col">
            <p className="font-bold text-ds-h5 px-lg flex pt-xl">
              Réglement de la facture
              <span className="ml-auto text-primary">{facture.prixTotalTTC}€</span>
            </p>
            <Elements options={{ locale: 'fr' }} stripe={stripePromise}>
              <FacturePaymentCardForm
                email={facture.deal.customerEmail}
                name={facture.deal.customerName}
                token={facture.token}
                onPaymentCompleted={() => setCompleted(true)}
              />
            </Elements>
          </div>
          <FinancialReassuranceBlock />
          <ReassuranceBlock vertical reassuranceItems={factureReassuranceItems} />
        </div>
      )}
    </div>
  );
};
