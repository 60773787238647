import React from 'react';

import { Deal } from '@travauxlib/shared/src/types';

type Props = {
  deal: Deal;
};

export const DealDestinataire: React.FC<Props> = ({ deal }) => (
  <div className="inline-block bg-lighter p-md">
    <p>A l'attention de :</p>
    <div className="font-bold">{deal.customerName}</div>
    <div>
      {deal.billingAddress.address} <br />
      {deal.billingAddress.postalCode} {deal.billingAddress.city}
    </div>
    <br />
    {deal.chantierAddress.address !== deal.billingAddress.address && (
      <>
        <div className="font-bold">Adresse du chantier :</div>
        {deal.chantierAddress.address} <br />
        {deal.chantierAddress.postalCode} {deal.chantierAddress.city}
      </>
    )}
  </div>
);
