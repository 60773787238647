import React from 'react';

import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { CompanyInfos } from 'components/DocumentHeader/CompanyInfos';
import { SuiviChantierClient } from 'types';

type Props = {
  suiviChantier: SuiviChantierClient;
  completionPercentage: number;
};

export const SuiviChantierContentHeader: React.FC<Props> = ({
  suiviChantier,
  completionPercentage,
}) => (
  <>
    <div className="flex justify-between pb-[5.5rem]">
      {suiviChantier.proCompany && <CompanyInfos company={suiviChantier.proCompany} />}
      <div>
        {suiviChantier.proDevisNumero && (
          <div className="mb-xl">
            <div className="font-bold">
              Suivi de chantier du devis n°{suiviChantier.proDevisNumero}
            </div>
            Dernière mise à jour: {formatFrenchDate(suiviChantier.updatedAt)}
          </div>
        )}
        {suiviChantier.proClient && (
          <div className="inline-block bg-lighter p-md">
            <p>A l'attention de :</p>
            <div className="font-bold">{suiviChantier.proClient.name}</div>
            <div>{suiviChantier.proClient.email}</div>
            <div>
              {suiviChantier.proClient.billingFormattedAddress} <br />
            </div>
          </div>
        )}
      </div>
    </div>
    <div className="flex justify-between items-center py-md">
      <div className="text-ds-h4 pb-xl">{suiviChantier.title}</div>
      <div className="text-ds-h5 font-bold mr-md">
        <div>État d'avancement global</div>
        <div className="text-primary flex justify-end">{completionPercentage} %</div>
      </div>
    </div>
  </>
);
