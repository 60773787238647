import React from 'react';

import classNames from 'classnames';

import { Middle } from './types';

import { Button } from '../Buttons/Button';
import { IconButton } from '../Buttons/IconButton';
import { Tag } from '../Tag';

type Props = {
  middle: Middle;
  children?: React.ReactNode;
  disabled?: boolean;
  shouldTruncate: boolean;
};

export const MiddlePart: React.FC<Props> = ({
  middle: { label, buttons, iconButtons, title, tags },
  children,
  disabled,
  shouldTruncate,
}) => (
  <div className={classNames('grow flex flex-col gap-xs', { truncate: shouldTruncate })}>
    <div className="flex justify-between gap-xxs">
      <div className={classNames({ truncate: shouldTruncate })}>
        <div
          className={classNames('text-neutral-800 font-bold', {
            'text-neutral-300': disabled,
            truncate: shouldTruncate,
          })}
        >
          {title}
        </div>
        {label && (
          <div
            className={classNames('text-neutral-600', {
              'text-neutral-300': disabled,
              truncate: shouldTruncate,
            })}
          >
            {label}
          </div>
        )}
      </div>
      {tags?.length && (
        <div className="flex gap-xxs items-start">
          {tags.map(tag => (
            <Tag
              {...tag}
              key={tag?.label?.toString() ?? ''}
              size={tag?.size ?? 'md'}
              variant={tag?.variant ?? 'beige'}
            />
          ))}
        </div>
      )}
    </div>
    <>
      {buttons && (
        <div className="flex gap-xs">
          {buttons.map(button => (
            <Button
              key={button.label}
              size="sm"
              {...button}
              onClick={event => {
                event.stopPropagation();
                button.onClick?.(event);
              }}
              disabled={disabled}
            >
              {button.label}
            </Button>
          ))}
        </div>
      )}
      {iconButtons && (
        <div className="flex gap-xs">
          {iconButtons.map((iconButton, index) => (
            <IconButton
              key={index}
              {...iconButton}
              onClick={event => {
                event.stopPropagation();
                iconButton.onClick?.(event);
              }}
              disabled={disabled}
            >
              {iconButton.icon}
            </IconButton>
          ))}
        </div>
      )}
      {children && <div>{children}</div>}
    </>
  </div>
);
