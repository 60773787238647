import React from 'react';

import classNames from 'classnames';

import CheckCircle from '@travauxlib/shared/src/components/DesignSystem/assets/CheckCircle.svg?react';
import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { Company } from '@travauxlib/shared/src/types/company';
import { companyStatusIds } from '@travauxlib/shared/src/utils/constants';

type Props = {
  company: Company;
  isHemea?: boolean;
};

export const CompanyInfos: React.FC<Props> = ({ company, isHemea }) => {
  const {
    name,
    email,
    address,
    city,
    phoneNumber,
    postalCode,
    bic,
    iban,
    isLabelliseeOrActivee,
    numeroTVA,
    siret,
    RCS,
    capital,
    statutEntreprise,
    websites,
  } = company;

  return (
    <div>
      <div
        className={classNames('font-bold mb-0', {
          'mb-sm': isHemea && isLabelliseeOrActivee,
        })}
      >
        {name}
      </div>
      {isHemea && isLabelliseeOrActivee && (
        <div className="flex items-center bg-light -py-xxs px-xs text-ds-sm">
          <CheckCircle className="text-success w-md" />
          <span className="font-bold ml-xs">Entreprise labellisée hemea</span>
        </div>
      )}
      <div>{address}</div>
      <div>
        {postalCode} {city}
      </div>
      {websites?.personalWebsite && <div>Site internet : {websites.personalWebsite}</div>}
      <div>Email : {email}</div>
      <div className="mb-xs">Tél. : {phoneNumber}</div>
      <small className="block text-gray-600">
        {statutEntreprise !== companyStatusIds.Autre && (
          <>
            {statutEntreprise}{' '}
            {!!capital && (
              <>
                au capital de <EURCurrency amount={capital} />
              </>
            )}
          </>
        )}
        <br />
        Siret : {siret} <br />
        {RCS ? `RCS ${RCS} - ` : ''}
        {siret ? `Siren : ${siret.slice(0, 9)}` : ''}
        <br />
        {numeroTVA ? `Nº TVA : ${numeroTVA}` : ''}
        {!isHemea && (
          <>
            <br />
            {iban ? `IBAN : ${iban}` : ''} <br />
            {bic ? `BIC : ${bic}` : ''}
          </>
        )}
      </small>
    </div>
  );
};
