import React from 'react';

import DotsMenu from '@travauxlib/shared/src/components/DesignSystem/assets/DotsMenu.svg?react';
import { ActionList } from '@travauxlib/shared/src/components/DesignSystem/components/ActionList';
import { IconButton } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/IconButton';
import { DevisComment } from '@travauxlib/shared/src/types';

import { CommentTag } from './CommentTag';

type Props = {
  comment: DevisComment;
  isDeleted: boolean;
  editComment: () => void;
  deleteComment: () => void;
};

export const CommentActions: React.FC<Props> = ({
  comment,
  isDeleted,
  editComment,
  deleteComment,
}) => (
  <>
    <CommentTag comment={comment} isDeleted={isDeleted} />
    {comment.senderType !== 'pro' && (
      <ActionList
        small
        trigger={(toggle, referenceProps) => (
          <IconButton
            onClick={toggle}
            {...referenceProps}
            className="ml-sm"
            size="md"
            data-testid="open-comment-actions"
          >
            <DotsMenu />
          </IconButton>
        )}
        items={[
          {
            type: 'action',
            label: 'Supprimer',
            onClick: () => deleteComment(),
          },
          {
            type: 'action',
            label: 'Éditer',
            onClick: () => editComment(),
          },
        ]}
      />
    )}
  </>
);
