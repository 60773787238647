import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import {
  Button,
  ICommonButtonProps,
} from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { useIsBelowBreakpoint } from '@travauxlib/shared/src/hooks/useIsBelowBreakpoint';
import { useOnClickOutside } from '@travauxlib/shared/src/hooks/useOnClickOutside';

import { SuiviAmounts } from './SuiviAmounts';

import { SuiviChantierComputed, SuiviLot } from '../types';

type Props = {
  lots: SuiviLot[];
  computed: SuiviChantierComputed;
  action: { label: string } & ICommonButtonProps;
  intervenant: 'client' | 'tp' | 'pro';
  children: ({
    showDetails,
    setShowDetails,
  }: {
    showDetails: boolean;
    setShowDetails: (showDetails: boolean) => void;
  }) => JSX.Element;
};

export const SuiviFooter: React.FC<Props> = ({ lots, computed, action, intervenant, children }) => {
  const isBelowSmDesktop = useIsBelowBreakpoint('sm-desktop');
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const { label, ...actionProps } = action;

  const detailsRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(detailsRef, () => {
    if (showDetails) {
      setShowDetails(false);
    }
  });

  return (
    <>
      <div
        className={classNames(
          'sticky bottom-0 top-lg z-20 mt-auto flex flex-col justify-between gap-md bg-neutral-0 px-md py-sm shadow-ds-xl col-span-full',
          'sm-desktop:col-span-3 sm-desktop:shadow-ds-xxs sm-desktop:mt-0 sm-desktop:justify-normal sm-desktop:mb-auto sm-desktop:rounded-xxs',
          { 'rounded-t-md': showDetails },
        )}
        ref={detailsRef}
      >
        {intervenant !== 'client' && (!isBelowSmDesktop || showDetails) && (
          <SuiviAmounts lots={lots} computed={computed} />
        )}
        {children({ showDetails, setShowDetails })}
        <Button {...actionProps} fullwidth>
          {action.label}
        </Button>
      </div>
    </>
  );
};
