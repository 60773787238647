import React from 'react';

import { useParams } from 'react-router-dom';

import ArrowLeft from '@travauxlib/shared/src/components/DesignSystem/assets/ArrowLeft.svg?react';
import Construction from '@travauxlib/shared/src/components/DesignSystem/assets/Construction.svg?react';
import Download from '@travauxlib/shared/src/components/DesignSystem/assets/Download.svg?react';
import HemeaM from '@travauxlib/shared/src/components/DesignSystem/assets/HemeaM.svg?react';
import { Card } from '@travauxlib/shared/src/components/DesignSystem/components/Card';
import { IconButtonLink } from '@travauxlib/shared/src/components/DesignSystem/components/Links';
import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { ItemLink } from '@travauxlib/shared/src/components/ItemLink';
import { formatFrenchDate } from '@travauxlib/shared/src/utils/time';

import { useGetDevisFactures } from 'features/EspaceClient3/api/useGetDevisFactures';

export const AllFactures: React.FC = () => {
  const { uuid, proCompanyUuid } = useParams<{ uuid: string; proCompanyUuid: string }>();
  const { devisFactures, isLoading } = useGetDevisFactures(uuid!);
  const devisFacturesForCompany = devisFactures?.proCompanies.find(
    proCompany => proCompany.uuid === proCompanyUuid,
  );

  if (isLoading) {
    return <LoaderWrapper />;
  }

  return (
    <div className="mx-md sm-desktop:mx-0">
      <div className="flex items-center gap-xxs text-ds-h5 text-neutral-800 font-bold my-sm">
        <IconButtonLink to=".." title="Toutes mes factures">
          <ArrowLeft />
        </IconButtonLink>
        Toutes mes factures
      </div>
      {Object.entries(devisFacturesForCompany?.devisByProjectUuid ?? {}).map(
        ([proProjectUuid, { mainDevis, factures }]) =>
          factures?.length > 0 && (
            <Card key={proProjectUuid} className="mb-md rounded-md" bodyClassNames="!px-0">
              <div className="text-ds-b1 text-neutral-800 font-bold mb-xs px-md">
                {mainDevis.title}
              </div>
              {factures.map(facture => (
                <ItemLink
                  key={facture.tokenOrId}
                  title={facture.title}
                  href={facture.detailsUrl}
                  label={`Envoyée le ${formatFrenchDate(facture.sentAt)}`}
                  price={facture.prixTotalTTC}
                  leftIcon={facture.isHemea ? <HemeaM /> : <Construction />}
                  rightIcon={<Download />}
                  rightLink={facture.downloadUrl}
                />
              ))}
            </Card>
          ),
      )}
    </div>
  );
};
