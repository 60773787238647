import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { LoaderWrapper } from '@travauxlib/shared/src/components/DesignSystem/components/Loader/LoaderWrapper';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { doesSuiviChantierEtapeExistsFactory } from '@travauxlib/shared/src/features/SuiviChantier/api/doesSuiviChantierEtapeExists';
import { SuiviChantier } from '@travauxlib/shared/src/features/SuiviChantier/features/Suivi';
import { useSuiviInitialValues } from '@travauxlib/shared/src/features/SuiviChantier/hooks/useSuiviInitialValues';
import {
  FormData,
  SuiviChantierIntervenant,
} from '@travauxlib/shared/src/features/SuiviChantier/types';

import { AvancementChantierTracking } from 'utils/tracking/AvancementChantierTracking';

import { useSaveSuiviChantierDraft } from './api/useSaveSuiviChantierDraft';
import { useSuiviChantier } from './api/useSuiviChantier';
import { useSuiviChantierDraft } from './api/useSuiviChantierDraft';
import { useValidateSuiviChantier } from './api/useValidateSuiviChantier';

import { useProject } from '../../api/useProject';

const useSuiviChantierExists = doesSuiviChantierEtapeExistsFactory(
  'client',
  SuiviChantierIntervenant.Client,
  APP_CONFIG,
);

export const SuiviChantierClient: React.FC = () => {
  const navigate = useNavigate();
  const { uuid, chantierUuid, suiviChantierUuid } = useParams<{
    uuid: string;
    chantierUuid: string;
    suiviChantierUuid: string;
  }>();
  const { suiviChantier, isLoading } = useSuiviChantier(chantierUuid!, suiviChantierUuid!);
  const { suiviChantierDraft, isLoading: isDraftLoading } = useSuiviChantierDraft(chantierUuid!);
  const saveSuiviChantierDraft = useSaveSuiviChantierDraft();
  const validateSuiviChantier = useValidateSuiviChantier();
  const { project } = useProject(uuid!);
  const soldeCompteSequestre = project?.chantiers.find(
    chantier => chantier.uuid === chantierUuid,
  )?.soldeCompteSequestre;
  const {
    suiviChantierEtapeExists,
    isLoading: suiviChantierExistsLoading,
    isCycleCanceled,
  } = useSuiviChantierExists(chantierUuid!, suiviChantierUuid!);

  const initialValues = useSuiviInitialValues({
    devisList: suiviChantier?.devisList,
    withSuiviStatus: true,
    suiviChantierDraft,
  });

  useEffect(() => {
    if (isCycleCanceled) {
      toast.warning(<>Cet état d'avancement a été annulé par hemea</>);
      navigate(
        `/projets/${uuid}/chantiers/${chantierUuid}/suivi-chantier-history/${suiviChantierUuid}?pro`,
      );
    }

    if (!suiviChantierExistsLoading && suiviChantierEtapeExists) {
      toast.warning(<>Vous avez déjà validé cet état d'avancement du chantier.</>);
      navigate(
        `/projets/${uuid}/chantiers/${chantierUuid}/suivi-chantier-history/${suiviChantierUuid}?client`,
      );
    }
  }, [suiviChantierExistsLoading, suiviChantierEtapeExists, chantierUuid]);

  const isDataIncomplete =
    isDraftLoading ||
    isLoading ||
    !chantierUuid ||
    !suiviChantierUuid ||
    !suiviChantier ||
    suiviChantierExistsLoading;

  if (isDataIncomplete) {
    return <LoaderWrapper />;
  }

  const initialValuesCreatedByProStep = suiviChantier.devisList
    .flatMap(devis =>
      devis.lots.map(lot => ({
        ...lot,
        devisToken: devis.token,
        lignes: lot.lignes.map(l => ({
          ...l,
          pourcentageAvancement:
            l.proPreviousStepPourcentageAvancement ?? l.previousStepPourcentageAvancement,
          hasFournitures: l.proPreviousStepHasFournitures ?? l.previousStepHasFournitures,
        })),
      })),
    )
    .reduce((acc, lot) => ({ ...acc, [lot.uuid]: lot }), {});

  return (
    <div className="flex h-full bg-neutral-100">
      <SuiviChantier
        suiviChantier={suiviChantier}
        initialValues={initialValues}
        initialValuesCreatedByProStep={initialValuesCreatedByProStep}
        handleSubmit={async suiviChantierData => {
          await validateSuiviChantier({ chantierUuid, suiviChantierUuid, ...suiviChantierData });
          AvancementChantierTracking.onAvancementChantierValidated({
            'Project Name': project?.title,
            'Project Uuid': project?.uuid,
          });
        }}
        onBack={() => navigate(`/projets/${uuid}/paiements/${chantierUuid}`)}
        intervenant="client"
        onSaveDraft={(values: FormData) =>
          saveSuiviChantierDraft({ chantierUuid: chantierUuid, formValues: JSON.stringify(values) })
        }
        soldeCompteSequestre={soldeCompteSequestre}
      />
    </div>
  );
};
