import React, { ReactNode } from 'react';

import classNames from 'classnames';

export const PaidFacture: React.FC<{ children: ReactNode; className?: string }> = ({
  children,
  className,
}) => (
  <div
    className={classNames(
      'rounded-[80px] border border-success p-md w-[360px] text-[40px] text-success',
      className,
    )}
  >
    {children}
  </div>
);
